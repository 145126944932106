import React  from 'react';
import { BrowserRouter as Router,Routes ,Route } from "react-router-dom";
import { render } from 'react-dom';
import ProductionLaunch from "./click-to-production";
import StagingLaunch from "./click-to-staging";
import DevLaunch from "./click-to-dev";

import "./index.css";
 function App() {  
 
  return (       
    <Router>
      <Routes>
          <Route path='/' element={<ProductionLaunch/>} />
          <Route path='/app' element={<ProductionLaunch/>} />
          <Route path='/app3' element={<StagingLaunch />} />
          <Route path='/dev' element={<DevLaunch />} />
      </Routes>  
    </Router>  
  );
}

 
render(<App/>, document.getElementById("root"));
 
import React, { useEffect, useState } from "react";
sessionStorage.setItem("isAuthenticated", "0");
export default function stagingApp() {
  {
    let baseWebUrl =process.env.REACT_APP_STG,
    baseApiUrl = process.env.REACT_APP_API_STG,
    environment = "STG";
  console.log(
    "baseWebUrl:" +
      baseWebUrl +
      ",baseApiUrl:" +
      baseApiUrl +
      ",environment:" +
      environment
  );
 
  const [authFields, setAuthFields] = useState([
    { name: "Token", id: "txtToken", value: "", placeholder: "Enter Token" },
    {
      name: "RClick Id",
      id: "txtRClickId",
      value: "",
      placeholder: "Enter Id",
    },
    {
      name: "RClick Key",
      id: "txtRClickKey",
      value: "",
      placeholder: "Enter Key",
    },
  ]);
  const [submitFields, setSubmitFields] = useState([
    {
      name: "Signer Payload",
      id: "txtSignControls",
      value: "",
      placeholder: "Enter Payload",
    },
  ]);
  const [isAuthModal, setIsAuthModal] = useState(true);
  const [isSubmitModal, setIsSubmitModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseData, setResponseData] = useState(null);
  const [isDisableSubmit, setIsDisableSubmit] = useState(false);
  useEffect(() => {
    const script = document.createElement("script");
    // Set RClick external file source
    script.src = baseWebUrl + "scripts/custom/sign.rclick.js";
    // Set async to true for asynchronous loading
    script.async = true;

    // Append the script tag to the document head
    document.head.appendChild(script);
    if (!isAuthModal) {
      const authToken = authFields.find(
        (field) => field.id === "txtToken"
      ).value;
      const rclickId = authFields.find(
        (field) => field.id === "txtRClickId"
      ).value;
      const rclickKey = authFields.find(
        (field) => field.id === "txtRClickKey"
      ).value;
      // loadRClickScript function that will be called once the script is loaded
      const loadRClickScript = () => {
        const data = JSON.stringify({
          rclickId: rclickId,
          rclickKey: rclickKey,
          token: authToken,
          environment: environment,
          signControls: [
            {
              signControlId: "rs-Signature1",
              signHiddenControlId: "hdnSignatureID1",
            },
          ],
        });

        // Now that the script is loaded, we can use functions defined in the external file

        rclick.run(data);
      };

      // Attach the event listener for the script load event
      script.addEventListener("load", loadRClickScript);
      const button = document.getElementById("submitOnClick");

      // Add the event listener to the button
      const generateandSubmitDocument = () => {
        if(document.getElementById('rs-Signature1').innerHTML == '')
          {
            showResponseMessage("Signature Control is mandatory to proceed.", 0);
            return false;
          }
        let hdnSignatureID = document.getElementById("hdnSignatureID1").value;
        if (
          !isData(hdnSignatureID) ||
          hdnSignatureID === "00000000-0000-0000-0000-000000000000"
        ) {
          showResponseMessage("Signature is mandatory to proceed.", 0);
          return false;
        }

        setIsSubmitModal(true);
      };
      button.addEventListener("click", generateandSubmitDocument);

      // Clean up the event listener when the component is unmounted
      return () => {
        document.head.removeChild(script);
        button.removeEventListener("click", generateandSubmitDocument);
        script.removeEventListener("load", loadRClickScript);
      };
    }
  });

  const handleAuthentication = async () => {
    const authToken = authFields.find((field) => field.id === "txtToken").value;
    const rclickId = authFields.find(
      (field) => field.id === "txtRClickId"
    ).value;
    const rclickKey = authFields.find(
      (field) => field.id === "txtRClickKey"
    ).value;
    if (!isData(authToken)) {
      showResponseMessage("Token is mandatory to proceed.", 0);
      return false;
    } else if (!isData(rclickId)) {
      showResponseMessage("RClick Id is mandatory to proceed.", 0);
      return false;
    } else if (!isData(rclickKey)) {
      showResponseMessage("RClick Key is mandatory to proceed.", 0);
      return false;
    }
    setIsAuthModal(false);
  };
  const handleSubmit = async () => {
    
    let submitPayload =  submitFields.find(
      (field) => field.id === "txtSignControls"
    ).value;
    if (!isJsonString(submitPayload)) {
      showResponseMessage("Please enter valid JSON to proceed.", 0);
      return false;
    }
    submitPayload=JSON.parse(submitPayload);
    let isValid = checkValidation(submitPayload);
    if (!isValid) return false;
    let authToken = authFields.find((field) => field.id === "txtToken").value;
    submitPayload.SignerList[0].DataControls.find(
      (field) => field.ControlHtmlID.indexOf("signControl") > -1
    ).ControlValue = document.getElementById("hdnSignatureID1").value; // Testing perpurse
    setIsSubmitModal(false);
    showResponseMessage("Document digital signature processing...", 2);
    setIsLoading(true);
    console.log(submitPayload);
    const url = baseApiUrl + "api/V1/Envelope/SendClickSignEnvelope";
    fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        AuthToken: authToken,
      },
      body: JSON.stringify(submitPayload),
    })
      .then((response) => response.json())
      .then((result) => {
        setResponseData(result);
        setIsLoading(false);
        showResponseMessage("Document digital signature completed..", 1);
        let res = JSON.stringify(result);
        alert("RClick response:" + res);
        submitPayload.ReturnFinalSignedDocument == "1" ? setIsDisableSubmit(true) : refreshPage();
        //console.log("RClick response:", result);
        return result;
      })
      .catch((error) => {
        setIsLoading(false);
        showResponseMessage(error, 3);
      });
  };
  const handleInputChange = (id, value, isAuth) => {
    if (isAuth) {
      const updatedFields = authFields.map((field) =>
        field.id === id ? { ...field, value } : field
      );
      setAuthFields(updatedFields);
    } else {
      const updatedFields = submitFields.map((field) =>
        field.id === id ? { ...field, value } : field
      );
      setSubmitFields(updatedFields);
    }
  };
  const validEmail = (email) => {
    let regex = new RegExp(
      /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i
    );
    let isValid = regex.test(email);
    return isValid;
  };
  function checkValidation(submitPayload) {
    
    if (!isData(submitPayload.TemplateID)) {
      showResponseMessage("Template Id is mandatory to proceed.", 0);
      return false;
    } else if (!isData(submitPayload.Subject)) {
      showResponseMessage("Subject is mandatory to proceed.", 0);
      return false;
    } else if (!isData(submitPayload.Body)) {
      showResponseMessage("Body is mandatory to proceed.", 0);
      return false;
    } else if (!isData(submitPayload.SignerList.length>0)) {
      showResponseMessage("SignerList is mandatory to proceed.", 0);
      return false;
    } else if (!isData(submitPayload.SignerList[0].RoleName)) {
      showResponseMessage("RoleName is mandatory in SignerList to proceed.", 0);
      return false;
    } else if (!isData(submitPayload.SignerList[0].Name)) {
      showResponseMessage("Name is mandatory in SignerList to proceed.", 0);
      return false;
    } else if (!validName(submitPayload.SignerList[0].Name)) {
      showResponseMessage("Please provide valid name, special characters and numbers are not allowed", 0);
      return false;
    } else if (!isData(submitPayload.SignerList[0].Email)) {
      showResponseMessage("Email is mandatory in SignerList to proceed.", 0);
      return false;
    } else if (!validEmail(submitPayload.SignerList[0].Email)) {
      showResponseMessage("Please provide valid email.", 0);
      return false;
    } else if (
      submitPayload.SendEmail != "1" &&
      submitPayload.ReturnFinalSignedDocument != "1"
    ) {
      showResponseMessage(
        'Please include the value "1" in either Send Email or Return Final Sign Document is mandatory to proceed.',
        0
      );
      return false;
    } else if (submitPayload.SignerList[0].DataControls.length == 0) {
      showResponseMessage(
        "DataControls are mandatory in SignerList to proceed.",
        0
      );
      return false;
    }
    return true;
  }
  const isData = (value) => {
    return value === "" ||
      value === undefined ||
      value === null ||
      (typeof value === "string" && value.trim() === "")
      ? false
      : true;
  };
  const validName = (name) => {
    let nameregex = new RegExp(
      /^[a-zA-Z'\s]{1,50}$/
    );
    let isValidname = nameregex.test(name);
    return isValidname;
  };
  const showResponseMessage = (message, type) => {
    toastr.options = {
      debug: false,
      closeButton: true,
      newestOnTop: true,
      progressBar: true,
      positionClass: "toast-top-right",
      preventDuplicates: true,
      onclick: null,
      showDuration: "300",
      hideDuration: "1000",
      timeOut: "5000",
      extendedTimeOut: "1000",
      showEasing: "swing",
      hideEasing: "linear",
      showMethod: "fadeIn",
      hideMethod: "fadeOut",
    };
    switch (type) {
      case 0:
        toastr.warning(message);
        break;
      case 1:
        toastr.success(message);
        break;
      case 2:
        toastr.info(message);
        break;
      case 3:
        toastr.error(message);
        break;
    }
  };

  const isJsonString = (str) => {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  };
  function CopyFinalSignedDoc() {
    if (responseData && responseData.FinalSignedDocumentInBase64) {
      navigator.clipboard
        .writeText(responseData.FinalSignedDocumentInBase64)
        .then(() => {
          showResponseMessage("Copied successfully.",1);
          refreshPage();
        })
        .catch((err) => {
          console.error("Failed to copy: ", err);
        });
    } else {
      alert("Not defined");
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }

  const Styles = {
    spinner: {
      animation: "spinner-animation 900ms linear infinite",
      display: "inline-block",
      width: "30px",
      height: "30px",
      position: "fixed",
      top: "50%",
      left: "50%",
    },
    circle: {
      stroke: "#979797",
      strokeOpacity: "0.25",
    },

    segment: {
      stroke: "#2E3A4B",
    },
  };
  return (
    <>
      <div>
        <h2>RClick 2024</h2>
        <p> Verify your chit details! </p>
        <p> Please check the following details and submit the form </p>
        <table className="invoice">
          <tbody>
            <tr>
              <td valign="top">
                Prasanth Kumar
                <br />
                Disney_testing_shootday
                <br />
                Feb. 27, 2023, 9 a.m. <br />
                Day Type: Standard Day
              </td>
            </tr>
            <tr>
              <td valign="top">
                <table
                  className="invoice-items"
                  cellPadding="0"
                  cellSpacing="0"
                >
                  <tbody>
                    <tr>
                      <td valign="top">Basic Rate</td>
                      <td align="right" valign="top">
                        £ 145.05
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Accommodation</td>
                      <td align="right" valign="top">
                        £ 0.00
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Broken Lunch</td>
                      <td align="right" valign="top">
                        £ 20.10
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Night Shoot Broken Lunch</td>
                      <td align="right" valign="top">
                        £ 0.00
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Supplementary Performance</td>
                      <td align="right" valign="top">
                        £ 78.89
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Other Expenses</td>
                      <td align="right" valign="top">
                        £ 0.00
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Overtime</td>
                      <td align="right" valign="top">
                        £ 0.00
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Holiday Credit</td>
                      <td align="right" valign="top">
                        £ 15.62
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Travel Allowance</td>
                      <td align="right" valign="top">
                        £ 13.87
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Fitting Fee</td>
                      <td align="right" valign="top">
                        £ 0.00
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Adjustment</td>
                      <td align="right" valign="top">
                        £ 0.00
                      </td>
                    </tr>
                    <tr>
                      <td valign="top">Reason For Adjustment</td>
                      <td align="right" valign="top">
                        Just for checking
                      </td>
                    </tr>
                    <tr className="total">
                      <td width="80%" align="right" valign="top">
                        Total
                      </td>
                      <td align="right" valign="top">
                        £ 273.53
                      </td>
                    </tr>
                    <tr>
                      <td align="left" valign="top">
                        <div id="rs-Signature1"></div>
                        <input type="hidden" id="hdnSignatureID1" />
                      </td>
                    </tr>
                    <tr>
                      <td width="80%" align="right" valign="top">
                        <div>
                          {responseData &&
                            responseData.Success &&
                            responseData.FinalSignedDocumentInBase64 !== "" && (
                              <button
                                style={{ background: "#2f45c1" }}
                                onClick={() => CopyFinalSignedDoc(responseData)}
                              >
                                Copy
                              </button>
                            )}
                        </div>
                      </td>
                      <td align="right" valign="top">
                        <button
                          disabled={isDisableSubmit}
                          id="submitOnClick"
                          data-mdb-ripple-init
                          data-mdb-modal-init
                          data-mdb-target="#staticBackdrop"
                        >
                          Submit
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {isAuthModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="RobotoFont my-4 res-font14">
                {" "}
                Signature Payload{" "}
              </h5>
            </div>

            {authFields.map((field) => (
              <div className="input-content-wrap" key={field.id}>
                <div className="w-30p">
                  <label className="font-600 ">{field.name}</label>
                </div>
                <div className="w-70p">
                  <input
                    className="input-field form__input w-95"
                    placeholder={field.placeholder}
                    type="text"
                    value={field.value}
                    onChange={(e) =>
                      handleInputChange(field.id, e.target.value, true)
                    }
                  />
                </div>
              </div>
            ))}
            <div className="footer-btn">
              <button
                type="button"
                style={{ marginLeft: "16px" }}
                className="submitButtion btn green"
                onClick={(e) => handleAuthentication(e)}
                data-bs-dismiss="modal"
              >
                Authenticate
              </button>
            </div>
          </div>
        </div>
      )}
        {isSubmitModal && (
        <div className="modal-overlay">
          <div className="modal-content" style={{ background: "#f5f6fa" }}>
            <div className="input-Line">
              <h5 className="RobotoFont my-4 res-font14">
                Final Document Payload
              </h5>
            </div>

            {submitFields.map((field) => (
              <div className="input-content-wrap" key={field.id}>
                <input
                  className="input-field form__input w-95 submit-Input"
                  placeholder={field.placeholder}
                  type="text"
                  value={field.value}
                  onChange={(e) =>
                    handleInputChange(field.id, e.target.value, false)
                  }
                />
              </div>
            ))}
            <div className="footer-btn">
              <button
                type="button"
                style={{ marginLeft: "16px" }}
                className="submitButtion btn green"
                onClick={(e) => handleSubmit(e)}
                data-bs-dismiss="modal"
              >
                Send
              </button>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <span>
          <svg style={Styles.spinner} viewBox="0 0 42 42">
            <g fill="none" transform="translate(3 3)" strokeWidth="3">
              <circle style={Styles.circle} cx="18" cy="18" r="18" />

              <path
                style={Styles.segment}
                d="M36 18c0-9.94-8.06-18-18-18"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </svg>
        </span>
      ) : null}
    </>
  );
}
}